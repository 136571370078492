import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import store from './state/store'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import VueClazyLoad from 'vue-clazy-load'
import BreadCrumbs from '@/components/header/BreadCrumbs'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'

import Toasted from 'vue-toasted'

import onlyInt from 'vue-input-only-number'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft'

import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'

import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Sentry.init({
  dsn: 'https://d224ac4c2879a21585b84c173b396336@sentry.rentmagic.net/22',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/westvlaanderen\.rentmagic\.net\/api/,
    'https://uitleendienst.west-vlaanderen.be'
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

import '@/globalComponents'

library.add(
  faDownload,
  faTh,
  faPlus,
  faMinus,
  faThList,
  faCreditCard,
  faSignOutAlt,
  faSearchPlus,
  faUserCircle,
  faUserSecret,
  faInfoCircle,
  faBan,
  faSync,
  faShoppingCart,
  faHourglassHalf,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFacebookSquare,
  faEnvelope,
  faTwitterSquare,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faTimesCircle,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faUserEdit,
  faYoutube,
  faFilePdf,
  faSpinner,
  faTrash,
  faCheckCircle,
  faRedo
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('bread-crumbs', BreadCrumbs)

Vue.use(require('vue-moment'))
Vue.use(VueClazyLoad)
Vue.use(VueMeta)
Vue.use(onlyInt)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM },
  bootstrap: store.getters.getAcceptedCookiesState
})

Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000
})

window.VueCalendarLang = function() {
  return {
    daysOfWeek: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    limit: 'Limit reached ({{limit}} items max).',
    loading: 'Laden...',
    minLength: 'Min. Lengte',
    months: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'October',
      'November',
      'December'
    ],
    notSelected: 'Niets geselecteerd',
    required: 'Vereist',
    search: 'Zoeken'
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
